import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { StatusProcessoService } from '../../shared/service/status-processo.service';
import { StatusProcesso } from '../../shared/model/statusProcesso';

@Component({
    templateUrl: './status-processo.component.html',
    styleUrls: ['./status-processo.component.scss'],
    providers: [MessageService]
})
export class StatusProcessoComponent implements OnInit {

    disableField = false;
    loading = false;
    displayDialog = false;
    submitted = false;
    listStatus: StatusProcesso[] =[];
    status: StatusProcesso = {};

    constructor(private serviceStatus: StatusProcessoService ) { }

    ngOnInit() {
        this.recuperarStausLista();
    }

    recuperarStausLista(){
        this.serviceStatus.getStatusProcesso().subscribe((rs)=>{
            this.listStatus = rs;
        })
    }

    openNew(){
        // this.listStatus = [];
        this.status = {};
        this.submitted = false;
        this.displayDialog = true;
    }

    editStatus(item: StatusProcesso){

    }

    deleteStatus(item: StatusProcesso){
    }

    saveStatus(){
        this.submitted = true;
        // if(this.status.descricao_status){
        //     this.serviceStatus.createStatus(this.status).then(()=>{
        //         this.recuperarStausLista();
        //         this.displayDialog = false;
        //         this.status = {};
        //     })
        // }
    }



    onSubmit(){}
}
