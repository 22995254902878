import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { Router } from '@angular/router'; // Importe o Router

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent {
    items!: MenuItem[];
    dialogSair: boolean = false;
    userDados: any;
    grupoPetrobras: boolean = false;
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
    @ViewChild('topbarmenu') menu!: ElementRef;

    // Injetar o Router no construtor
    constructor(public layoutService: LayoutService, private router: Router) {}

    ngOnInit() {
        this.userDados = JSON.parse(this.recuperarStorage() || '{}');
        var grupoJSON = localStorage.getItem('dadosUser');

        if (grupoJSON !== null) {
            this.userDados = JSON.parse(grupoJSON);
        }

        if (
            this.userDados[0].grupo === 'admPetrobrasEquipamentos' ||
            this.userDados[0].grupo === 'clintePetrobrasEquipamentos'
        ) {
            this.grupoPetrobras = true;
        }
        console.log('user:', this.userDados);
    }

    recuperarStorage() {
        localStorage.removeItem('grupo');
        return localStorage.getItem('grupo');
    }

    sair() {
        try {
            // Remover dados específicos do localStorage
            localStorage.removeItem('dadosUser');

            // Realizar logout no serviço de layout
            this.layoutService.logout();

            // // Limpeza adicional (caso necessário)
            // localStorage.removeItem('token'); // Exemplo de remoção de token de autenticação
            // localStorage.removeItem('usuario'); // Exemplo de remoção de informações do usuário

            // Redirecionar para a página de login ou inicial
            this.router.navigate(['/login']); // Altere a rota conforme necessário
        } catch (error) {
            console.error('Erro ao sair da aplicação:', error);
        }
    }
}
