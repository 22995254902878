import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

interface MenuItem {
    label: string;
    icon?: string;
    routerLink?: string;
    items?: MenuItem[];
}

interface DataItem {
    code: string;
    name: string;
    contratoPai: string | null;
}

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];
    userDados: any;

    constructor(public layoutService: LayoutService) {}

    ngOnInit() {
        var grupoJSON = localStorage.getItem('dadosUser');

        if (grupoJSON !== null) {
            this.userDados = JSON.parse(grupoJSON);
        }
        this.model = [
            {
                label: 'Home',
                items: [
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-chart-bar',
                        routerLink: [''],
                    },
                ],
            },
            {
                label: 'Links',
                items: [
                    {
                        label: 'SmartQuestion',
                        icon: 'pi pi-fw pi-send',
                        routerLink: ['/redirecionamento/'],
                    },
                ],
            },
        ];

        if (this.userDados[0].almoxarifados) {
            this.model.push({
                label: 'Almoxarifado',
                items: this.buildDynamicMenu(this.userDados[0].almoxarifados),
            });
        }

        if (this.userDados[0].grupo === 'Adm') {
            this.model.push(
                {
                    label: 'Equipamentos Rio de Janeiro',
                    items: [
                        {
                            label: 'Dashboard',
                            icon: 'pi pi-fw pi-chart-bar',
                            routerLink: ['equipamentos-rj/dashboard'],
                        },
                        {
                            label: 'Inventário',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['equipamentos-rj/inventario'],
                        },
                    ],
                },
                {
                    label: 'Administração',
                    items: [
                        {
                            label: 'Contratos',
                            icon: 'pi pi-globe',
                            routerLink: ['administrativo/contratos'],
                        },
                        {
                            label: 'Usuários',
                            icon: 'pi pi-users',
                            routerLink: ['administrativo/usuarios'],
                        },
                    ],
                }
            );
        }

        if (
            this.userDados[0].grupo === 'admPetrobrasEquipamentos' ||
            this.userDados[0].grupo === 'clintePetrobrasEquipamentos'
        ) {
            this.model = [
                {
                    label: 'Equipamentos Rio de Janeiro',
                    items: [
                        {
                            label: 'Dashboard',
                            icon: 'pi pi-fw pi-chart-bar',
                            routerLink: ['equipamentos-rj/dashboard'],
                        },
                        {
                            label: 'Inventário',
                            icon: 'pi pi-fw pi-list',
                            routerLink: ['equipamentos-rj/inventario'],
                        },
                    ],
                },
            ];
        }
    }

    buildDynamicMenu(data: DataItem[]): MenuItem[] {
        const menu: MenuItem[] = [];
        const groupedData: Record<string, DataItem[]> = {};

        // Agrupar itens por contrato pai
        data.forEach((item) => {
            const { code, name, contratoPai } = item;
            if (!groupedData[contratoPai || '']) {
                groupedData[contratoPai || ''] = [];
            }
            groupedData[contratoPai || ''].push({ code, name, contratoPai });
        });

        // Criar o menu dinâmico
        for (const key in groupedData) {
            const submenu: MenuItem = {
                label: key,
                icon: 'pi pi-fw pi-briefcase',
                items: [],
            };
            groupedData[key].forEach((item) => {
                const rota = `/almoxarifado/${item.code}`;
                submenu.items?.push({
                    label: item.name,
                    routerLink: rota,
                });
            });
            menu.push(submenu);
        }

        return menu;
    }
}
