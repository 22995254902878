import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './guards/auth.guard';
import { NotfoundComponent } from './demo/components/notfound/notfound.component'; // Assumindo que você tenha um componente de "not found"
import { LoginComponent } from './auth/login/login.component'; // Assumindo que você tenha um componente de login
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./home/components/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipamentos-rj',
                loadChildren: () =>
                    import(
                        './home/components/equipamentos-rj/equipamentos-rj.module'
                    ).then((m) => m.EquipamentosRjModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'almoxarifado',
                loadChildren: () =>
                    import(
                        './home/components/almoxarifado/almoxarifado.module'
                    ).then((m) => m.AlmoxarifadoModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'administrativo',
                loadChildren: () =>
                    import(
                        './home/components/administrativo/administrativo.module'
                    ).then((m) => m.AdministrativoModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'redirecionamento',
                loadChildren: () =>
                    import(
                        './home/components/redirecionamento-page/redirecionamento.module'
                    ).then((m) => m.RedirecionamentoPageModule),
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'login',
        component: LoginComponent, // Componente de login
    },
    {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
