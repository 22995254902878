import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class StatusProcessoService {
    constructor( private http: HttpClient) {
    }

    private apiUrl = 'http://localhost:3333';


    getStatusProcesso(): Observable<any> {
        return this.http.get<any>(this.apiUrl + "/getStatus").pipe(
          catchError(this.handleError)
        );
    }

    createStatus(status: any): Observable<any> {
        return this.http.post<any>(this.apiUrl + "/createStatus", status).pipe(
          catchError(this.handleError)
        );
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return throwError(error.message || 'Server error');
    }
}
