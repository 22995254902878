<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Novo Status" icon="pi pi-plus" class="p-button-success mr-2"
                            (click)="openNew(); disableField = false"></button>
                    </div>
                </ng-template>
            </p-toolbar>
        </div>

        <p-table #dt [value]="listStatus" [rows]="10" [paginator]="true" [responsive]="true" [rowsPerPageOptions]="[5,10,20]"
            [globalFilterFields]="['descricao_status']" [autoLayout]="true" [scrollable]="true" [scrollHeight]="'calc(100vh - 300px)'"
            [loading]="loading" [loadingIcon]="'pi pi-spinner'">
            <ng-template pTemplate="caption">
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <h5 class="m-0">Lista Status</h5>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id_status">ID<p-sortIcon field="id_status"></p-sortIcon></th>
                    <th pSortableColumn="descricao_status">Descrição<p-sortIcon field="descricao_status"></p-sortIcon></th>
                    <th pSortableColumn="dataCriacao">Data de Criação <p-sortIcon field="dataCriacao"></p-sortIcon></th>
                    <th >Motivo Criação</th>
                    <th style="width:8rem">Ação</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-status>
                <tr>
                    <td style="width:10%; min-width:10rem;">
                        <span class="p-column-title">ID</span>
                        {{status.id_status}}
                    </td>

                    <td >
                        <span class="p-column-title text-center">Descrição</span>
                        {{status.descricao_status}}
                    </td>

                    <td style="width:20%; min-width:10rem;">
                        <span class="p-column-title">Data de Criação</span>
                        {{status.data_criacao | date: 'dd/MM/yyyy'}}
                    </td>

                    <td >
                        <span class="p-column-title text-center">Motivo Criação</span>
                        {{status.motivo_status}}
                    </td>

                    <td style="width:14%; min-width:10rem;">
                        <span class="p-column-title">Ação</span>
                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-2"
                            (click)="editStatus(status)"></button>
                        </td>
                </tr>
            </ng-template>
        </p-table>

        <p-dialog header="Status" [(visible)]="displayDialog" [responsive]="true" [style]="{width: '450px'}"
            [modal]="true" [closable]="true" [closeOnEscape]="true" [dismissableMask]="true">
            <ng-template pTemplate="content">
                <div class="p-fluid">
                    <div class="p-field">
                        <label for="descricao_status">Descrição</label>
                        <!-- <input type="text" pInputText id="email" [(ngModel)]="usuario.email" required autofocus
                        [ngClass]="{'ng-invalid ng-dirty' : submitted && !usuario.email}" mask="email"
                        [disabled]="disableField" /> -->
                        <input pInputText id="descricao_status" [(ngModel)]="status.descricao_status" required autofocus
                        [ngClass]="{'ng-invalid ng-dirty' : submitted && !status.descricao_status}"/>
                        <small class="ng-dirty ng-invalid" *ngIf="submitted && !status.descricao_status">Descrição obrigatória.</small>
                        <label for="motivo_status">Motivo</label>
                        <textarea id="motivo_status" pInputTextarea [(ngModel)]="status.motivo_status" rows="5" cols="30" required
                        [disabled]="disableField"></textarea>
                    </div>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !status.motivo_status">Motivo obrigatório.</small>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                    (click)="displayDialog = false"></button>
                <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-text"
                    (click)="saveStatus()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>
