import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StatusProcessoComponent } from './components/status-processo/status-processo.component';

@NgModule({
    imports: [RouterModule.forChild([
     { path: 'usuarios', data: { breadcrumb: 'Usuarios' }, loadChildren: () => import('./components/users-crud/user-crud.module').then(m => m.UserCrudModule) },
     { path: 'contratos', data: { breadcrumb: 'Contratos' }, loadChildren: () => import('./components/contratos-crud/contratos-crud.module').then(m => m.UserCrudModule) },
     { path: 'categorias', data: { breadcrumb: 'Categorias Material' }, loadChildren: () => import('./components/contratos-crud/contratos-crud.module').then(m => m.UserCrudModule) },
    {
        path: 'status-processo',
        children: [
            {
                path: '',
                component: StatusProcessoComponent,
            }
        ]
    }

    ])],
    exports: [RouterModule]
})
export class AdministrativoRoutingModule { }
